import { initializeApp } from 'firebase/app';
import {
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { ENV } from '../env';
import { useCallback, useState } from 'react';
import { LoggerService } from '../logger';
import { BhabiStorage } from './storage';
import { User, getUser, updateUserState } from '../../redux/reducers';
import { Capacitor } from '@capacitor/core';
import { noop } from 'lodash';
import { api } from '../../api';
import { dispatch } from '@/redux';

// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
export const firebaseApp = initializeApp(ENV.FIREBASE_CONFIG);

const getFirebaseAuth = () => {
  if (
    Capacitor.getPlatform() === 'ios' ||
    Capacitor.getPlatform() === 'android'
  ) {
    return initializeAuth(firebaseApp, {
      persistence: indexedDBLocalPersistence,
    });
  }

  return getAuth(firebaseApp);
};
export const firebaseAuth = getFirebaseAuth();
export const db = getFirestore(firebaseApp);

export const useFirebase = () => {
  const [isProcessing, setIsProcessing] = useState(false);

  const verifyStoredToken = useCallback(
    async (cb: (isAuth: boolean) => void) => {
      BhabiStorage.getItem(async (token, err) => {
        if (err || !token) {
          cb(false);
          return;
        }
        LoggerService.info('Token found in storage: ', !!token);
        const user = await api<User>({
          method: 'GET',
          url: '/user',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }).catch(() => {
          LoggerService.info('Token is invalid, removing...');
          BhabiStorage.removeItem('token');
          cb(false);
          throw new Error('Token is invalid');
        });

        dispatch(
          updateUserState({
            isAuthenticated: true,
            isIdle: false,
            isLoading: false,
            isError: false,
            ...user.data,
          })
        );

        cb(true);
      });
    },
    []
  );

  const checkFirebaseAuth = useCallback(
    (cb: (isAuth: boolean) => void = noop) => {
      firebaseAuth.onAuthStateChanged(async user => {
        LoggerService.info('Auth state changed: ', !!user);

        if (!user) {
          LoggerService.info('No user found');
          setIsProcessing(true);
          await verifyStoredToken(cb);
          setIsProcessing(false);
          return;
        }
        setIsProcessing(true);

        const updateToken = async () => {
          const token = await user.getIdToken();
          LoggerService.info(`Setting new token...`);
          BhabiStorage.setToken(token);
          dispatch(getUser(token));
          setIsProcessing(false);
          cb(true);
        };

        await updateToken();
      });
    },
    [verifyStoredToken]
  );

  return { isProcessing, checkFirebaseAuth, verifyStoredToken };
};
