import {
  SliceCaseReducers,
  SliceSelectors,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { api } from '../../api';
import { LoggerService } from '../../shared/logger';
import { REDUCER_KEYS } from './const';
import { BhabiStorage } from '../../shared/lib';

export interface User {
  id: string;
  email: string;
  name: string;
  activatedAt: {
    Time: string;
    Valid: boolean;
  };
  state: 'online' | 'offline' | 'joined' | 'rejoined' | 'left';
  // photo from google or uploaded by user
  photoURL: string | null;
  firebaseUID: string | null;
}

export interface UserState extends User {
  isAuthenticated: boolean;
  isIdle: boolean;
  isLoading: boolean;
  isError: boolean;
  token: string | null;
  isInRoom?: boolean;
}

export const userInitialState: UserState = {
  id: '',
  email: '',
  name: '',
  activatedAt: {
    Time: '',
    Valid: false,
  },
  state: 'offline',
  firebaseUID: null,
  isAuthenticated: false,
  isIdle: true,
  isLoading: false,
  isError: false,
  token: null,
  isInRoom: false,
  photoURL: null,
};

export const userSlice = createSlice<
  UserState,
  SliceCaseReducers<UserState>,
  string,
  SliceSelectors<UserState>
>({
  name: REDUCER_KEYS.USER,
  initialState: userInitialState,
  reducers: {
    updateUserState: (
      state,
      payload: { type: string; payload: Partial<UserState> }
    ) => {
      return {
        ...state,
        ...payload.payload,
      };
    },
    logoutUser: () => ({
      ...userInitialState,
      isIdle: false,
    }),
  },
  extraReducers: builder => {
    builder
      .addCase(getUser.pending, state => {
        state.isIdle = false;
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
        };
      })
      .addCase(getUser.rejected, state => {
        state.isLoading = false;
        state.isError = true;
        state.isAuthenticated = false;
      });
  },
});

export const getUser = createAsyncThunk(
  'userSlice/getUser',
  async (token: string) => {
    try {
      const response = await api<User>({
        method: 'GET',
        url: '/user',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });

      await BhabiStorage.setItem('token', token);

      return {
        ...response.data,
        isAuthenticated: true,
        isIdle: false,
        isLoading: false,
        isError: false,
        token,
      };
    } catch (error) {
      LoggerService.error('Error fetching user:', error);
      await BhabiStorage.removeItem('token');
      throw error;
    }
  }
);

export const { updateUserState, logoutUser } = userSlice.actions;

export const userReducer = userSlice.reducer;
