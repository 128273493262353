import {
  SliceCaseReducers,
  SliceSelectors,
  createSlice,
} from '@reduxjs/toolkit';
import { REDUCER_KEYS } from './const';
import { InPlayRoomWsResponse } from '@/screens/authenticated/types';

export declare type InPlayState = InPlayRoomWsResponse;

const initialState: InPlayState = {
  gameInfo: {
    room: null,
    userDeck: null,
    inPlayCards: null,
    deck: null,
  },
  players: [],
  showRequestCardsOption: false,
  concludedCards: 0,
  missingOwner: false,
  ownerUsername: '',
};

export const inPlaySlice = createSlice<
  InPlayState,
  SliceCaseReducers<InPlayState>,
  string,
  SliceSelectors<InPlayState>
>({
  name: REDUCER_KEYS.IN_PLAY,
  initialState,
  reducers: {
    setInPlayState: (
      state,
      payload: { type: string; payload: InPlayState }
    ) => ({
      ...state,
      ...payload.payload,
    }),
  },
});
export const { setInPlayState } = inPlaySlice.actions;

export const inPlayReducer = inPlaySlice.reducer;
