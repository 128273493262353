import React, { FC, PropsWithChildren, useMemo } from 'react';
import { Avatar, AvatarProps, Badge } from '@mui/material';
import { omit } from 'lodash';
import { createAvatar } from '@dicebear/core';
import { initials } from '@dicebear/collection';

import { useSelector } from 'react-redux';
import { Store } from '../../redux';

interface UserAvatarProps {
  photoURL?: string | null;
  name?: string;
  userState?: 'online' | 'offline' | 'joined' | 'rejoined' | 'left';
  // hideState is used to hide the state badge
  hideState?: boolean;
  avatarProps?: AvatarProps;
  fetchCurrentUser?: boolean;
}

/**
 * Fallback to current user avatar if photoURL is not provided.
 */
export const UserAvatar: FC<UserAvatarProps> = ({
  photoURL,
  name,
  avatarProps,
  fetchCurrentUser,
  userState,
  hideState = false,
}) => {
  const {
    name: currentUserName,
    photoURL: currentUserPhotoURL,
    state: currentUserState,
  } = useSelector((state: Store) => state.userReducer);

  const avatarUri = useMemo(() => {
    if (!name && !currentUserName) {
      return '';
    }
    return createAvatar(initials, {
      seed: name || currentUserName,
    }).toDataUri();
  }, [currentUserName, name]);

  const photo = useMemo(() => {
    if (fetchCurrentUser) {
      return currentUserPhotoURL || avatarUri;
    }

    return photoURL || avatarUri;
  }, [avatarUri, currentUserPhotoURL, fetchCurrentUser, photoURL]);

  const state = useMemo(
    () => (fetchCurrentUser ? currentUserState : userState),
    [currentUserState, fetchCurrentUser, userState]
  );

  const avatar = (
    <Avatar
      sx={{
        width: 35,
        height: 35,
        ...avatarProps?.sx,
      }}
      {...{
        src: photo,
      }}
      {...omit(avatarProps, 'sx')}
    />
  );

  return (
    <>
      {!hideState ? (
        <UserAvatarStateBadge state={state}>{avatar}</UserAvatarStateBadge>
      ) : (
        avatar
      )}
    </>
  );
};

export const UserAvatarStateBadge: FC<
  PropsWithChildren<{ state: UserAvatarProps['userState'] }>
> = ({ children, state }) => {
  const badgeColor = useMemo(() => {
    if (state === 'online') {
      return 'success';
    }

    if (state === 'joined' || state === 'rejoined') {
      return 'success';
    }

    if (state === 'offline' || state === 'left') {
      return 'error';
    }

    return 'error';
  }, [state]);

  return (
    <Badge color={badgeColor} overlap="circular" badgeContent=" " variant="dot">
      {children}
    </Badge>
  );
};
