import React, { PropsWithChildren, type FC, useMemo } from 'react';
import { createBrowserRouter, RouterProvider as RP } from 'react-router-dom';
import {
  authenticatedRoutes,
  createRoomRoutes,
  gameRulesRoutes,
  inRoomRoutes,
  resetPasswordRoutes,
  signInRoutes,
  signUpRoutes,
  tocRoutes,
  verifyEmailRoutes,
} from '../screens';
import { useIsAuth } from '../shared/hooks';
import { LoadingLayout, notFoundRoute } from '../shared/components';

const unAuthRoutes = [signUpRoutes, resetPasswordRoutes];

const alwaysIncludedRoutes = [
  signInRoutes,
  tocRoutes,
  notFoundRoute,
  gameRulesRoutes,
];

export const GameRoutes: FC<PropsWithChildren> = () => {
  const { isAuthenticated, isLoading, isIdle } = useIsAuth();

  const routes = useMemo(() => {
    if (isLoading || isIdle) {
      return [];
    }

    if (isAuthenticated) {
      return [
        verifyEmailRoutes(),
        ...authenticatedRoutes,
        ...inRoomRoutes,
        createRoomRoutes(),
      ];
    }

    return unAuthRoutes;
  }, [isAuthenticated, isIdle, isLoading]);

  if (isLoading || isIdle) {
    return <LoadingLayout />;
  }

  const r = createBrowserRouter([...routes, ...alwaysIncludedRoutes]);

  return <RP router={r} fallbackElement={<LoadingLayout />} />;
};
