import React, { FC, useMemo, useRef, useState } from 'react';
import { useSearchUser } from '../../invite-players/hooks';
import { API_URLS } from '../../consts';
import {
  Box,
  BoxProps,
  Chip,
  ClickAwayListener,
  Paper,
  Popper,
  TextFieldProps,
  Typography,
  alpha,
  lighten,
  styled,
} from '@mui/material';
import { BhabiFirestoreModel } from '../components/message';
import {
  collection,
  deleteDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../../../../shared/lib';
import { useFirestoreQuery } from '../components';
import {
  ConfirmDeleteButton,
  ErrorLayout,
  LoadingLayout,
  UserAvatar,
} from '../../../../shared/components';
import {
  InvitePlayerPageWrapper as PageWrapper,
  InvitePlayerPageContentWrapper as PageContentWrapper,
  SearchUser,
} from '../../invite-players/components';

import { groupBy } from 'lodash';
import { API_SERVICE_URL } from '../../../../api';
import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { Store } from '../../../../redux';
import { User } from '../../types';
import { blueGrey } from '@mui/material/colors';

const SearchUserBar: FC<{
  textFieldProps?: TextFieldProps;
  onClickAvatar: DMPageProps['onClickThread'];
}> = ({ textFieldProps, onClickAvatar }) => {
  const { onChange, userInfo, noUserFound } = useSearchUser(
    `${API_URLS.SEARCH_USERS}?search=@&limit=20`,
    { limit: '20' }
  );
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const searchRef = useRef<HTMLDivElement>(null);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event);
    setAnchorEl(searchRef.current);
  };

  return (
    <PageWrapper>
      <div className="mt-2">
        <SearchUser
          textFieldProps={{
            ref: searchRef,
            onChange: handleSearchChange,
            onBlur: () => noUserFound && setAnchorEl(null),
            ...textFieldProps,
          }}
        />
      </div>
      {noUserFound && anchorEl ? (
        <Typography variant="body1" textAlign="center">
          No user found
        </Typography>
      ) : (
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
            <UserViewWrapper
              width="100%"
              sx={{
                borderRadius: 4,
                background: theme =>
                  lighten(
                    blueGrey[theme.palette.mode === 'dark' ? 900 : 500],
                    0.4
                  ),
                padding: 1,
              }}
              mt={1}
            >
              {userInfo?.map(user => (
                <Box
                  className="inner-div"
                  key={user.id}
                  onClick={onClickAvatar(user.firebaseUID)}
                >
                  <UserAvatar
                    {...{
                      photoURL: user.photoURL,
                      name: user.name,
                      avatarProps: {
                        sx: {
                          cursor: 'pointer',
                        },
                      },
                    }}
                  />
                  <Chip label={user.name} size="small" sx={{ maxWidth: 80 }} />
                </Box>
              ))}
            </UserViewWrapper>
          </Popper>
        </ClickAwayListener>
      )}
    </PageWrapper>
  );
};

const UserViewWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gridTemplateRows: 'auto',
  gap: theme.spacing(1),
  '& .inner-div': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const useGetFirebaseUsers = () => {
  const { token } = useSelector((state: Store) => state.userReducer);

  return useMutation<User[], AxiosError, string[]>({
    mutationKey: 'getFirebaseUsersByUIDs',
    mutationFn: async (ids: string[]) => {
      const url = [
        `${API_SERVICE_URL}${API_URLS.GET_USERS_BY_FIREBASE_UID}`,
        '?ids=',
        ids.join(','),
      ].join('');
      const res = await axios.get<User[]>(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    },
  });
};

export const useDmPageQuery = (getOtherUserInfo = true) => {
  const { firebaseUID } = useSelector((state: Store) => state.userReducer);
  const { mutate: getFirebaseUsers, ...remaining } = useGetFirebaseUsers();

  const qc = useMemo(
    () =>
      !firebaseUID
        ? []
        : [
            orderBy('createdAt', 'desc'),
            limit(50),
            where('threadID', 'array-contains', firebaseUID),
          ],
    [firebaseUID]
  );

  const messages = useFirestoreQuery(qc) as BhabiFirestoreModel[];

  const pageData = useMemo(() => {
    if (!messages?.length || !firebaseUID) {
      return {
        uniqueMessagePerUser: [],
        threadWithIds: [],
      };
    }

    const thread = groupBy(messages, m => m.threadID.sort().toString());
    const threadWithIds = Object.keys(thread).map(id =>
      id
        .split(',')
        .filter(id => id !== firebaseUID)
        .join('')
    );

    if (getOtherUserInfo) {
      getFirebaseUsers(threadWithIds);
    }

    const uniqueMessagePerUser = Object.entries(thread).map(([, value]) => ({
      message: value[value.length - 1].message,
      isRead: value[value.length - 1].isRead,
      senderID: value[value.length - 1].senderID,
      receiverID: value[value.length - 1].receiverID,
      createdAt: value[value.length - 1].createdAt,
    }));

    const sortByTime =
      uniqueMessagePerUser.length > 1
        ? uniqueMessagePerUser.sort(
            (a, b) => b.createdAt?.seconds - a.createdAt?.seconds
          )
        : uniqueMessagePerUser;

    return { uniqueMessagePerUser: sortByTime, threadWithIds };
  }, [messages, firebaseUID, getFirebaseUsers, getOtherUserInfo]);

  return { pageData, firebaseUID, ...remaining };
};

interface DMPageProps {
  onClickThread: (id?: string) => () => void;
  pageContentWrapperProps?: BoxProps;
  textFieldProps?: TextFieldProps;
}

export const DMPage: FC<DMPageProps> = ({
  onClickThread,
  pageContentWrapperProps,
  textFieldProps,
}) => {
  const { pageData, firebaseUID, isError, isLoading, data } = useDmPageQuery();

  if (isLoading && !isError) {
    return <LoadingLayout height="auto" />;
  }

  if (!firebaseUID || isError) {
    return <ErrorLayout outerBoxProps={{ height: 'auto' }} />;
  }

  const onClickDeleteThread =
    (receiverID: string, senderID: string) => async () => {
      const chatCollection = collection(db, 'chat');

      // Query for threadID == [receiverID, senderID]
      const q1 = query(
        chatCollection,
        where('threadID', '==', [receiverID, senderID])
      );
      const querySnapshot1 = await getDocs(q1);
      querySnapshot1.forEach(doc => {
        deleteDoc(doc.ref);
      });

      // Query for threadID == [senderID, receiverID]
      const q2 = query(
        chatCollection,
        where('threadID', '==', [senderID, receiverID])
      );
      const querySnapshot2 = await getDocs(q2);
      querySnapshot2.forEach(doc => {
        deleteDoc(doc.ref);
      });
    };

  return (
    <Box px={1}>
      <SearchUserBar
        textFieldProps={textFieldProps}
        onClickAvatar={onClickThread}
      />
      <PageWrapper>
        <PageContentWrapper pt={2} {...pageContentWrapperProps}>
          {data && pageData.uniqueMessagePerUser?.length ? (
            pageData.uniqueMessagePerUser?.map((message, i) => (
              <MessageTileWrapper
                key={i}
                component={Paper}
                onClick={onClickThread(
                  pageData.threadWithIds.find(
                    id => id === message.senderID || id === message.receiverID
                  )
                )}
                isRead={
                  message.senderID !== firebaseUID ? message.isRead : 'true'
                }
              >
                <Box
                  {...{
                    position: 'absolute',
                    top: 5,
                    right: 5,
                  }}
                >
                  <ConfirmDeleteButton
                    onClick={onClickDeleteThread(
                      message.receiverID,
                      message.senderID
                    )}
                  />
                </Box>
                <UserAvatar
                  {...{
                    name: data?.find(
                      findUserById(message.receiverID, message.senderID)
                    )?.name,
                    photoURL:
                      data?.find(
                        findUserById(message.receiverID, message.senderID)
                      )?.photoURL || null,
                    hideState: true,
                  }}
                />
                <Box className="message-tile-inner-box">
                  <Typography variant="h6" fontWeight={500} color="primary">
                    {data?.find(
                      findUserById(message.receiverID, message.senderID)
                    )?.name || 'Unknown'}
                  </Typography>
                  <Typography variant="body2">{message.message}</Typography>
                </Box>
              </MessageTileWrapper>
            ))
          ) : (
            <Typography variant="body2" textAlign="center">
              No messages thread found, search a user and start chat.
            </Typography>
          )}
        </PageContentWrapper>
      </PageWrapper>
    </Box>
  );
};

function findUserById(receiverID: string, senderID: string) {
  return (user: User) =>
    user.firebaseUID === receiverID || user.firebaseUID === senderID;
}

const MessageTileWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== 'isRead',
})<{ isRead: string }>(({ theme, isRead }) => ({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: theme.spacing(1),
  minHeight: 70,
  padding: theme.spacing(2),
  maxWidth: 500,
  cursor: 'pointer',
  backgroundColor:
    isRead === 'false'
      ? theme.palette.secondary.light
      : alpha(theme.palette.background.paper, 0.8),
  '& .message-tile-inner-box': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
}));
