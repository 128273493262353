import {
  SliceCaseReducers,
  SliceSelectors,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { REDUCER_KEYS } from './const';
import { LoggerService } from '@/shared/logger';
import { StatusBar, Style } from '@capacitor/status-bar';
import { PaletteMode } from '@mui/material';
import { Capacitor } from '@capacitor/core';

if (Capacitor.getPlatform() !== 'web') {
  StatusBar.hide().then(() => {
    LoggerService.info('StatusBar hidden');
  });
}
const changeStatusBarStyle = (mode: PaletteMode) => {
  if (Capacitor.getPlatform() === 'web') {
    return;
  }

  StatusBar.setStyle({
    style: mode === 'light' ? Style.Light : Style.Dark,
  }).then(() => {
    LoggerService.info('StatusBar style set to', mode);
  });
};

export interface ThemeSliceState {
  mode: 'light' | 'dark';
}

// Only dark theme is used in the app for version 4.0.
export const themeSlice = createSlice<
  ThemeSliceState,
  SliceCaseReducers<ThemeSliceState>,
  string,
  SliceSelectors<ThemeSliceState>
>({
  name: REDUCER_KEYS.THEME,
  initialState: {
    mode: 'light',
  },
  reducers: {
    changeTheme: (
      state,
      payload: { type?: string; payload: 'light' | 'dark' }
    ) => {
      changeStatusBarStyle(payload.payload);
      return {
        ...state,
        mode: payload.payload,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(getStoredMode.fulfilled, (state, action) => {
      changeStatusBarStyle(action.payload.mode);
      return {
        ...state,
        mode: action.payload.mode,
      };
    });
  },
});
export const { changeTheme } = themeSlice.actions;

export const themeReducer = themeSlice.reducer;

export const getStoredMode = createAsyncThunk(
  `${REDUCER_KEYS.THEME}/getStoredMode`,
  async () => {
    try {
      // const response = await BhabiStorage.getItem(noop, 'mode');

      return {
        mode: 'dark' as PaletteMode,
      };
    } catch (error) {
      LoggerService.error('Error fetching stored theme mode:', error);
      throw error;
    }
  }
);
