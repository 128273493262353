import React, { FC, useEffect } from 'react';
import { UseHttpMutationOptions, useHttpMutation } from '../../../api';
import { AxiosRequestConfig } from 'axios';
import { BhabiLink, useAlert } from '../../../shared/components';
import {
  Box,
  Button,
  Stack,
  TextField,
  Theme,
  Typography,
  styled,
} from '@mui/material';
import { Texts } from '../../../shared/types';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CREATE_ROOM_ROUTE } from './route.definitions';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserState } from '../../../redux/reducers';
import { BhabiStorage, adMob, usePushNotifications } from '../../../shared/lib';
import { useNavigate } from 'react-router-dom';
import { IN_PLAY_ROUTE } from '../in-play';
import { API_URLS } from '../consts';
import { Store } from '../../../redux';
import { GAME_RULES_ROUTES } from '../../unauthenticated';

export declare type CreateRoomInput = {
  NumberOfPlayers: number;
};

export const useCreateRoom = (
  options?: UseHttpMutationOptions,
  config?: AxiosRequestConfig
) =>
  useHttpMutation<{ token: string }, CreateRoomInput>(
    API_URLS.CREATE_ROOM,
    'POST',
    options,
    config
  );

export const CreateRoomPageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(3),
  paddingTop: theme.spacing(10),
  maxWidth: 1000,
  margin: '0 auto',
}));

export const createRoomFormStyled = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '75%',
  margin: '0 auto',
  padding: theme.spacing(3),
  gap: theme.spacing(3),
  textAlign: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    width: '94%',
  },
});

export interface CreateRoomPageProps {
  texts?: Texts<'createRoomButton' | 'message' | 'createRoom'>;
}

export const CreateRoomPage: FC<CreateRoomPageProps> = ({
  texts = {
    createRoomButton: 'Create Room',
    message: 'Minimum 2 players and maximum 9 players',
    createRoom: CREATE_ROOM_ROUTE.TITLE,
  },
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateRoomInput>({
    mode: 'onChange',
    defaultValues: {
      NumberOfPlayers: NaN,
    },
  });

  const { mutate: createRoom } = useCreateRoom();
  const { addAlert } = useAlert();
  const dispatch = useDispatch();
  const { isInRoom } = useSelector((state: Store) => state.userReducer);
  const nav = useNavigate();
  usePushNotifications();

  useEffect(() => {
    if (isInRoom) {
      nav(IN_PLAY_ROUTE.ABSOLUTE_PATH);
    }
  }, [isInRoom, nav]);

  useEffect(() => {
    (async function () {
      await adMob.banner();
    })();

    return () => {
      (async function () {
        await adMob.removeBanner();
      })();
    };
  }, []);

  const onSubmit: SubmitHandler<CreateRoomInput> = data => {
    createRoom(
      { NumberOfPlayers: Number(data.NumberOfPlayers) },
      {
        onError: () => {
          addAlert({
            message: 'Error creating room',
            type: 'error',
          });
        },
        onSuccess: res => {
          // update token, this token will be used to join the room
          BhabiStorage.setToken(res.token);
          dispatch(
            updateUserState({
              isInRoom: true,
              token: res.token,
            })
          );
          nav(IN_PLAY_ROUTE.ABSOLUTE_PATH);
        },
      }
    );
  };

  return (
    <CreateRoomPageWrapper>
      <Typography variant="h1" fontWeight={600}>
        {texts.createRoom}
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        textAlign="center"
        px={2}
        width={{
          xs: '100%',
          sm: '80%',
          md: '70%',
          lg: '65%',
        }}
      >
        Create a room and invite your friends to play Bhabi. Game allows maximum
        9 players. For game rules and how to play, please visit{' '}
        {<BhabiLink to={GAME_RULES_ROUTES.ABSOLUTE_PATH}>rules page</BhabiLink>}
        .
      </Typography>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={createRoomFormStyled}
      >
        <TextField
          type="number"
          sx={{ width: '100%' }}
          {...register('NumberOfPlayers', {
            required: 'Number of players is required',
            min: 2,
            max: 9,
          })}
          error={!!errors.NumberOfPlayers}
          placeholder="Number of players"
          helperText={
            <Box
              sx={{
                color: theme =>
                  errors?.NumberOfPlayers?.message
                    ? theme.palette.error.main
                    : theme.palette.warning.main,
              }}
              component="span"
            >
              {errors.NumberOfPlayers?.message || texts.message}
            </Box>
          }
        />
        <Button type="submit" variant="contained" fullWidth>
          {texts.createRoomButton}
        </Button>
      </Stack>
    </CreateRoomPageWrapper>
  );
};
