import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { WEBSOCKET_URLS } from '../../../shared/consts';
import {
  Badge,
  Box,
  IconButton,
  Paper,
  Popper,
  Typography,
  styled,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { dispatch, Store } from '../../../redux';
import { LoggerService } from '../../../shared/logger';
import { Room, User } from '../types';
import { useHttpMutation } from '../../../api';
import { API_URLS } from '../consts';
import { BhabiStorage, soundEffect } from '../../../shared/lib';
import { getUser, updateUserState } from '../../../redux/reducers';
import { IN_PLAY_ROUTE } from '../in-play';
import { useNavigate } from 'react-router-dom';
import { BsBell } from 'react-icons/bs';
import {
  ActionButtonStyled,
  NotificationStyled,
  RoomInfoGridStyled,
  roomInfoGridClassNames,
} from './styled';

export const InviteNotifierWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(1),
  left: theme.spacing(1),
  zIndex: 1000,
  cursor: 'pointer',
}));

export declare type InviteNotificationResponse = {
  id: string;
  userID: string;
  fromUser: User;
  to: string;
  toUser: User;
  roomID: string;
  room: Room;
  status: 'pending' | 'rejoin';
  createdAt: string;
  updatedAt: string;
};

const menuId = 'primary-notification-menu';

export const InviteNotifier: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isInviteMenuOpen = Boolean(anchorEl);

  const { mutate: rejectInvite, isLoading: isRejectInviteLoading } =
    useHttpMutation<unknown, { inviteID: string }>(
      API_URLS.REJECT_INVITE,
      'POST'
    );

  const { mutate: joinRoom, isLoading: isJoinRoomLoading } = useHttpMutation<
    { token: string },
    { roomID: string }
  >(API_URLS.JOIN_ROOM, 'POST');

  const { mutate: reJoinRoom, isLoading: isRejoinRoomLoading } =
    useHttpMutation<{ token: string }, { roomID: string }>(
      API_URLS.RE_JOIN_ROOM,
      'POST'
    );

  const nav = useNavigate();
  const { token, isInRoom } = useSelector((state: Store) => state.userReducer);
  const { lastJsonMessage, sendMessage } = useWebSocket<{
    invites: InviteNotificationResponse[];
  } | null>(`${WEBSOCKET_URLS().INVITE_NOTIFICATION}?token=Bearer ${token}`, {
    onOpen: () => {
      LoggerService.info('Websocket opened: connected to notification');
      dispatch(updateUserState({ state: !isInRoom ? 'online' : 'joined' }));
    },
    onError: () =>
      LoggerService.error('Websocket error: error connecting to notification'),
    shouldReconnect: () => true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      sendMessage('Ping');
    }, 2000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(prev => (!prev ? event.currentTarget : null));
  }, []);

  const rejectInviteHandler = useCallback(
    (inviteID: string) => {
      rejectInvite(
        { inviteID },
        {
          onSuccess: () => {
            sendMessage('update');
          },
        }
      );
    },
    [rejectInvite, sendMessage]
  );

  const acceptInviteHandler = useCallback(
    (roomID: string) => {
      joinRoom(
        { roomID },
        {
          onSuccess: res => {
            BhabiStorage.setToken(res.token);
            dispatch(getUser(res.token));
            nav(IN_PLAY_ROUTE.ABSOLUTE_PATH);
          },
        }
      );
    },
    [joinRoom, nav]
  );

  const rejoinInviteHandler = useCallback(
    (roomID: string) => {
      reJoinRoom(
        { roomID },
        {
          onSuccess: res => {
            BhabiStorage.setToken(res.token);
            dispatch(getUser(res.token));
            nav(IN_PLAY_ROUTE.ABSOLUTE_PATH);
          },
        }
      );
    },
    [nav, reJoinRoom]
  );

  const selector = useCallback(
    (n: InviteNotificationResponse) => ({
      pending: {
        title: (
          <Typography variant="body1">
            {`${n.fromUser.name} has invited you to join room`}
          </Typography>
        ),
        actionButtons: (
          <>
            <ActionButtonStyled
              variant="contained"
              color="success"
              size="small"
              disabled={isJoinRoomLoading || n.room.isFull}
              onClick={() => acceptInviteHandler(n.roomID)}
            >
              Accept
            </ActionButtonStyled>
            <ActionButtonStyled
              variant="outlined"
              color="error"
              size="small"
              disabled={isRejectInviteLoading}
              onClick={() => rejectInviteHandler(n.id)}
            >
              Reject
            </ActionButtonStyled>
          </>
        ),
      },
      rejoin: {
        title: (
          <Typography
            variant="body1"
            sx={{
              '& .room_id': {
                color: theme => theme.palette.secondary.main,
                fontWeight: 500,
              },
            }}
          >
            {`${n.fromUser.name} has rejoined Room`}
            <Typography className="room_id" fontSize="small">
              ID: {n.roomID.split('-')[0]}
            </Typography>
          </Typography>
        ),
        actionButtons: (
          <>
            <ActionButtonStyled
              variant="contained"
              color="success"
              size="small"
              disabled={isRejoinRoomLoading}
              onClick={() => rejoinInviteHandler(n.roomID)}
            >
              Rejoin
            </ActionButtonStyled>
            <ActionButtonStyled
              variant="outlined"
              color="error"
              size="small"
              disabled={isRejectInviteLoading}
              onClick={() => rejectInviteHandler(n.id)}
            >
              Reject
            </ActionButtonStyled>
          </>
        ),
      },
    }),
    [
      acceptInviteHandler,
      isJoinRoomLoading,
      isRejectInviteLoading,
      isRejoinRoomLoading,
      rejectInviteHandler,
      rejoinInviteHandler,
    ]
  );

  const lastNotification = useRef<number | null>(null);
  useEffect(() => {
    if (!lastJsonMessage?.invites?.length) return;

    if (!lastNotification.current) {
      soundEffect.playSound(soundEffect.notification);
      lastNotification.current = lastJsonMessage?.invites?.length;
    }
  }, [lastJsonMessage?.invites?.length]);

  return (
    <>
      <IconButton onClick={handleMenuOpen}>
        <Badge badgeContent={lastJsonMessage?.invites?.length} color="success">
          <BsBell fontSize={24} />
        </Badge>
      </IconButton>
      <Popper
        open={isInviteMenuOpen}
        anchorEl={anchorEl}
        placement="bottom-start"
        id={menuId}
        hidden={!lastJsonMessage?.invites?.length}
        sx={{ zIndex: 3500 }}
      >
        <Box mx={0.5} component={Paper} elevation={6} className="px-2 py-3">
          {lastJsonMessage?.invites?.map((n, i) => (
            <NotificationStyled
              key={[n.fromUser.email, i].join('-')}
              component={Paper}
              mt={1}
            >
              <Box
                display="grid"
                gridTemplateColumns="auto auto auto"
                gap={1}
                p={1}
              >
                {selector(n)[n.status].title}
                {selector(n)[n.status].actionButtons}
              </Box>

              <RoomInfoGridStyled>
                <Box className={roomInfoGridClassNames.infoBox.name}>
                  <Typography
                    variant="body1"
                    className={roomInfoGridClassNames.infoBox.infoText}
                  >
                    {`Players: ${n.room.numberOfPlayers}`}
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" gap={1} px={1}>
                  {n.room.isFull ? (
                    <CloseIcon color="error" fontSize="small" />
                  ) : (
                    <CheckCircleIcon color="success" fontSize="small" />
                  )}
                  <Typography>{n.room.isFull ? 'Full' : 'Open'}</Typography>
                </Box>
              </RoomInfoGridStyled>
            </NotificationStyled>
          ))}
        </Box>
      </Popper>
    </>
  );
};
